import { Suspense, useEffect } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import logo from './../assets/images/poliwangi.png'
import { useAppContext } from "./app-provider"

const Loading = () => {
    const { setContentLoading } = useAppContext()

    useEffect(() => {
        setContentLoading(true)
    })

    return (<></>)
}

export const CreateComponent = (props) => {
    const Component = props.Component
    const Fallback = props?.fallback ?? Loading

    if (props.async) {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{props?.title ?? 'Untitled Page'} | Perpustakaan Politeknik Negeri Banyuwangi</title>
                </Helmet>
                <Suspense fallback={<Fallback />}>
                    <Component {...props} />
                </Suspense>
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{props?.title ?? 'Untitled Page'} | Perpustakaan Politeknik Negeri Banyuwangi</title>
                </Helmet>
                <Component {...props} />
            </HelmetProvider>
        )
    }
}

export const decodeHTMLEntities = (text) => {
    var textarea = document.createElement('textarea')
    textarea.innerHTML = text
    return textarea.value
}