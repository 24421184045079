import { ChevronDown, Edit, Menu, User } from 'react-feather'
import logo from './../../assets/images/poliwangi.png'
import { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../utils/app-provider'
import { Link, useNavigate } from 'react-router-dom'

const Navbar = (props) => {
    const navigate = useNavigate()
    const navRef = useRef()
    const { navbarConfiguration } = useAppContext()
    const [navbarActive, setNavbarActive] = useState(false)

    const redirect = (e) => {
        e.preventDefault()
        window.scrollTo(0, 0)
        const url = e.currentTarget.getAttribute('href'),
            urlRegex = /^(https|ftp):\/\/[^\s/$.?#].[^\s]*$/i
        if (!urlRegex.test(url)) {
            navigate(url)
        } else {
            window.open(url, '_blank')
        }
    }

    useEffect(() => {
        if (window.scrollY > 0) {
            navRef.current.classList.add('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
            setNavbarActive(true)
        } else {
            navRef.current.classList.remove('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
            setNavbarActive(false)
        }

        const handleScroll = () => {
            if (window.scrollY > 0) {
                navRef.current.classList.add('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
                setNavbarActive(true)
            } else {
                navRef.current.classList.remove('navbar-custom-bg', 'transition-all', 'shadow-md', 'shadow-slate-700/20')
                setNavbarActive(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [navbarConfiguration])

    return (
        <div className="flex px-8 py-4 w-100" ref={navRef}>
            <div className="flex items-center sm:w-3/12 gap-2 mr-auto">
                <img src={logo} className="sm:w-[55px] w-[45px]" alt="Logo website" />
                <div className={navbarActive ? "text-slate-600" : "text-white"}>
                    <span className="sm:text-xl text-md font-bold tracking-wide mb-0 pb-0 uppercase">UPT Perpustakaan</span>
                    <h1 className="sm:text-sm text-[10px] mt-[-3px] uppercase">Politeknik Negeri Banyuwangi</h1>
                </div>
            </div>
            <div className="flex mx-auto items-center justify-center">
                <ul className="hidden sm:flex items-center gap-5">
                    {
                        navbarConfiguration.map((val, key) => {
                            if (val?.submenu) {
                                return (
                                    <li className={navbarActive ? "group text-slate-600 relative" : "group text-slate-300 relative"} key={key}>
                                        <a href="" className={navbarActive ? "group-hover:text-black text-sm flex gap-2 items-center transition-all" : "group-hover:text-white text-sm flex gap-2 items-center transition-all"}>
                                            {val.label}
                                            <ChevronDown size={16} />
                                        </a>
                                        <div className="hidden group-hover:block w-[220px] p-3 bg-white rounded-lg absolute top-[110%] left-[-10px] transition-all">
                                            <ul>
                                                {
                                                    val.submenu.map((v, k) => (
                                                        <li className="w-100" key={k}>
                                                            <a href={v.url} onClick={redirect} className="text-slate-700 text-sm hover:text-black p-2 block">{v.label}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                )
                            } else {
                                return (
                                    <li className={navbarActive ? "group text-slate-600 relative" : "group text-slate-300 relative"} key={key}>
                                        <a href={val.url} onClick={redirect} className={navbarActive ? "group-hover:text-black text-sm flex gap-2 items-center transition-all" : "group-hover:text-white text-sm flex gap-2 items-center transition-all"}>{val.label}</a>
                                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </div>
            <div className="hidden sm:flex gap-3 sm:w-1/5 ml-auto text-white items-center justify-end">
                <Link to="/login" className={navbarActive ? "text-slate-600 hover:text-black text-sm font-medium flex gap-2 items-center transition-all" : "text-slate-300 hover:text-white text-sm font-medium flex gap-2 items-center transition-all"}>
                    <User size={16}></User>
                    Login
                </Link>
                |
                <Link to="/register" className={navbarActive ? "text-slate-600 hover:text-black text-sm font-medium flex gap-2 items-center transition-all" : "text-slate-300 hover:text-white text-sm font-medium flex gap-2 items-center transition-all"}>
                    <Edit size={16}></Edit>
                    Register
                </Link>
            </div>
            <div className={`sm:hidden flex gap-3 sm:w-1/5 ml-auto ${navbarActive ? 'text-slate-600' : 'text-slate-100'} items-center justify-end`}>
                <button className={`border ${navbarActive ? 'border-slate-600' : 'border-slate-100'} rounded-md p-2 hover:bg-slate-100/40 transition-all duration-500`}>
                    <Menu size={16} />
                </button>
            </div>
        </div>
    )
}

export default Navbar