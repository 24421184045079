import { Mail, Phone } from "react-feather"
import { useNavigate } from "react-router-dom"
import { BASE_URL, LIBRARY_URL, REPOSITORY_URL } from "../../config/config"

const TopBar = (props) => {
    const navigate = useNavigate()
    const redirect = (e) => {
        e.preventDefault()
        navigate(e.currentTarget.getAttribute('href'))
    }

    const handleOpenDropdown = (e) => {
        if(document.querySelector(e.target.getAttribute('data-target')).classList.contains('hidden')) {
            document.querySelector(e.target.getAttribute('data-target')).classList.remove('hidden')
        } else {
            document.querySelector(e.target.getAttribute('data-target')).classList.add('hidden')
        }
    }

    return (
        <div className="flex w-100 bg-blue-900 py-2 items-center px-8 z-[5555] relative">
            <div className="relative">
                <div>
                    <button type="button" className="inline-flex w-full justify-center gap-x-1.5 text-sm text-slate-200 hover:text-white" onClick={ handleOpenDropdown } data-target="#dropdownMenus" aria-expanded="true" aria-haspopup="true">
                        { props?.active == 'opac' ? "KATALOG PUSAT" : props?.active == 'repository' ? 'REPOSITORY' : "PORTAL PERPUSTAKAAN"}
                        <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>

                <div className="absolute left-0 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right z-[5555] mt-2 w-56 hidden dropdown-menu" id="dropdownMenus">
                    <div className="py-1">
                        <a href={BASE_URL} className={`text-gray-700 block px-4 py-2 border-orange-600 ${ props?.active == 'opac' ? '' : (props?.active == 'repository' ? '' : 'border-l-[2px]') }`}>
                            <h6 className={`font-bold ${ props?.active == 'opac' ? 'text-slate-700' : (props?.active == 'repository' ? '' : 'text-orange-600') } text-sm`}>PORTAL PERPUSTAKAAN</h6>
                            <span className="text-xs text-slate-500">Portal Informasi Perpustakaan</span>
                        </a>
                        <a href={LIBRARY_URL} className={`text-gray-700 block px-4 py-2 border-orange-600 ${ props?.active == 'opac' ? 'border-l-[2px]' : '' }`}>
                            <h6 className={`font-bold ${ props?.active == 'opac' ? 'text-orange-600' : 'text-slate-700' } text-sm`}>KATALOG PUSAT</h6>
                            <span className="text-xs text-slate-500">OPAC Pusat</span>
                        </a>
                        <a href={REPOSITORY_URL} className={`text-gray-700 block px-4 py-2 border-orange-600 ${ props?.active == 'repository' ? 'border-l-[2px]' : '' }`}>
                            <h6 className={`font-bold ${ props?.active == 'repository' ? 'text-orange-600' : 'text-slate-700' } text-sm`}>REPOSITORY</h6>
                            <span className="text-xs text-slate-500">Repository Institusi</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="hidden sm:block ml-auto">
                <div className="flex items-center">
                    <div className="flex items-center ml-8 group text-slate-200 cursor-pointer">
                        <Phone size={16} className="group-hover:text-white" />
                        <div className="ml-2 text-sm group-hover:text-white">(0333) 2993848</div>
                    </div>
                    <div className="flex items-center ml-8 group text-slate-200 cursor-pointer">
                        <Mail size={16} className="group-hover:text-white" />
                        <div className="ml-2 text-sm group-hover:text-white">perpustakaan@poliwangi.ac.id</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar