import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { CreateComponent } from "../utils/component"
import MainLayout from "../views/main"
import ServiceLayout from './../views/service/layout'
import ProfileLayout from "../views/profile/layout"
import ResourceLayout from "./../views/resources/layout"

// import component
const Login = React.lazy(() => import('./../views/auth'))
const Register = React.lazy(() => import('./../views/auth/register'))
const Home = React.lazy(() => import('./../views/home'))
const Service = React.lazy(() => import('./../views/service'))
const Profile = React.lazy(() => import('./../views/profile'))
const News = React.lazy(() => import('./../views/news'))
const Announcement = React.lazy(() => import('./../views/announcement'))
const Article = React.lazy(() => import('./../views/article'))
const NewsDetail = React.lazy(() => import('./../views/news/detail'))
const Journal = React.lazy(() => import('./../views/resources/journal'))
const Book = React.lazy(() => import('./../views/resources/book'))
const Form = React.lazy(() => import('./../views/resources/form'))
const Katalog = React.lazy(() => import('./../views/katalog'))
const Repository = React.lazy(() => import('./../views/repository'))

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route Component={ MainLayout }>
                    {/* <Route path="/" element={ <CreateComponent title="Beranda" Component={ Home } async /> } /> */}

                    {/* News */}
                    <Route path="news" element={ <CreateComponent title="" Component={ News } async />} />
                    <Route path="news/:slug" element={ <CreateComponent title="" Component={ NewsDetail } async />} />
                    
                    {/* Announcement */}
                    <Route path="announcements" element={ <CreateComponent title="" Component={ Announcement } async />} />
                    <Route path="announcements/:slug" element={ <CreateComponent title="" Component={ NewsDetail } async />} />
                    
                    {/* Announcement */}
                    <Route path="articles" element={ <CreateComponent title="" Component={ Article } async />} />
                    <Route path="articles/:slug" element={ <CreateComponent title="" Component={ NewsDetail } async />} />

                    {/* Katalog */}
                </Route>
                <Route path="/" element={ <CreateComponent title="Katalog Buku" Component={ Katalog } async /> } />
                {/* <Route path="repositories" element={ <CreateComponent title="Repository" Component={ Repository } async /> } /> */}

                <Route Component={ ServiceLayout }>
                    <Route path="services/:slug" element={ <CreateComponent title="Layanan" Component={ Service } async /> } />
                </Route>

                <Route Component={ ProfileLayout }>
                    <Route path="profiles/:slug" element={ <CreateComponent title="Profil" Component={ Profile } async /> } />
                </Route>

                <Route Component={ ResourceLayout }>
                    <Route path="resources/journals" element={ <CreateComponent title="e - Journals" Component={ Journal } async />} />
                    <Route path="resources/books" element={ <CreateComponent title="e - Books" Component={ Book } async />} />
                    <Route path="resources/forms" element={ <CreateComponent title="e - Journals" Component={ Form } async />} />
                </Route>

                <Route path="login" element={ <CreateComponent title="Login" Component={ Login } async />} />
                <Route path="register" element={ <CreateComponent title="Register" Component={ Register } async />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App